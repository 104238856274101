import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'

import { getDateTime } from '~/src/Lib/Utils'
import { createAppIsReadySelector } from '~/src/Store/utils'

import { ACTION } from './state'

const initialEventHistoryBundle = createAsyncResourceBundle({
  name: 'facilityIrrigationEventHistory',
  actionBaseType: 'FACILITY_IRRIGATION_EVENT_HISTORY',
  getPromise: ({ apiFetch, dispatch, store }) => {
    const now = getDateTime('now')
    const start = now.minus({ days: 1.5 }).toUTC().toISO()
    return apiFetch('/irrigationPortStatusLogHistory/', {
      ordering: 'start',
      pagination: 0,
      // eslint-disable-next-line babel/camelcase
      start__gte: start,
    }).then(response => {
      const selectedRooms = store.selectFacilityIrrigationSelectedRooms()
      const zones = store.selectZones()
      if (!selectedRooms.length) {
        // Initialize selectedRooms to rooms with irrigation history
        const roomSet = new Set()
        response.forEach(event => {
          const { [event.zones[0]]: firstZone } = zones
          if (firstZone) {
            roomSet.add(firstZone.room)
          }
        })
        if (roomSet.size > 0) {
          const roomsWithData = Array.from(roomSet)
          roomsWithData.sort()
          dispatch({ type: ACTION.SET_SELECTED_ROOMS, payload: roomsWithData })
        }
      }
      return response
    })
  },
  staleAfter: ms.minutes(1),
})

export default {
  ...initialEventHistoryBundle,
  reactFetchFacilityIrrigationEventHistory: createAppIsReadySelector({
    dependencies: [
      'selectFacilityIrrigationEventHistoryShouldUpdate',
      'selectQueryObject',
      'selectRouteInfo',
    ],
    resultFn: (shouldUpdate, { view }, { pattern }) => {
      if (shouldUpdate && pattern === '/irrigation' && view === 'schedules') {
        return { actionCreator: 'doFetchFacilityIrrigationEventHistory' }
      }
      return null
    },
  }),
}
