import { omit } from 'ramda'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'

import { getDateTime } from '~/src/Lib/Utils'
import { createAppIsReadySelector } from '~/src/Store/utils'

const initialScheduleHistoryBundle = createAsyncResourceBundle({
  name: 'facilityIrrigationScheduleHistory',
  actionBaseType: 'FACILITY_IRRIGATION_SCHEDULE_HISTORY',
  getPromise: ({ apiFetch }) => {
    const now = getDateTime('now')
    return apiFetch('/controllerSchedulePublishHistories/', {
      ordering: '-publish_on',
      pagination: 0,
      start: now.minus({ day: 2.5 }).toISO(),
    }).then(response => {
      const scheduleHistory = response.reduce((histories, schedulePublishHistory) => {
        const { lastPublishedSchedules, publishOn } = schedulePublishHistory
        Object.values(lastPublishedSchedules).forEach(({ automatedScheduleSettings, scheduleId, scheduleStartTime }) => {
          if (scheduleStartTime) {
            histories[scheduleId] ??= []
            histories[scheduleId].push({
              automatedScheduleSettings,
              publishOn: getDateTime(publishOn).toMillis(),
              scheduleStartTimeParams: omit(['year', 'month', 'day'], getDateTime(scheduleStartTime).toObject()),
            })
          }
        })
        return histories
      }, {})
      return scheduleHistory
    })
  },
  staleAfter: ms.minutes(5),
})

export default {
  ...initialScheduleHistoryBundle,
  reactFetchFacilityIrrigationScheduleHistory: createAppIsReadySelector({
    dependencies: [
      'selectFacilityIrrigationActiveSchedules',
      'selectFacilityIrrigationScheduleHistoryShouldUpdate',
      'selectQueryObject',
      'selectRouteInfo',
    ],
    resultFn: (activeSchedules, shouldUpdate, { view }, { pattern }) => {
      if (activeSchedules && shouldUpdate && pattern === '/irrigation' && view === 'schedules') {
        return { actionCreator: 'doFetchFacilityIrrigationScheduleHistory' }
      }
      return null
    },
  }),
}
