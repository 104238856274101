import { EMPTY_OBJECT } from './constants'

const defaultFormat = {
  thousandSeparator: ',',
  suffix: '',
}

/**
 *
 * @param {number} num number to format
 * @param {number} [precision] precision for decimal numbers
 * @param {Object} [format] additional formatting options
 * @param {string} [format.thousandSeparator=,]
 * @param {string} [format.suffix]
 * @returns {string} formatted numeric string
 */
export const formattedNumber = (num, precision = 0, formatParam = EMPTY_OBJECT) => {
  if (num == null || Number.isNaN(num)) return ''
  const format = { ...defaultFormat, ...formatParam }
  if (num === parseInt(num, 10) && num < 1000) {
    return format.suffix ? [num, format.suffix].join('\u202f') : String(num)
  }
  const factor = precision && 10 ** precision
  const number = precision ? Math.round(num * factor) / factor : num
  // Only remove trailing zeroes when there IS a decimal point
  const withPrecision = number.toFixed(precision).replace(
    /\.\d+$/,
    decimals => (decimals.match(/\.[0]+$/) ? '' : decimals.replace(/[0]+$/, ''))
  )
  const [integer, decimals] = withPrecision.split('.')
  if (!format.thousandSeparator || integer.length < 4) {
    return [withPrecision, format.suffix].filter(Boolean).join(' ')
  }
  const negative = integer.startsWith('-')
  const absInteger = negative ? integer.slice(1) : integer
  const thousands = []
  for (let i = absInteger.length; i > 0; i -= 3) {
    thousands.unshift(absInteger.slice(Math.max(0, i - 3), i))
  }
  let formattedNum = [thousands.join(format.thousandSeparator), decimals].filter(Boolean).join('.')
  formattedNum = format.suffix
    ? [formattedNum, format.suffix].join('\u202f')
    : formattedNum
  return negative ? `-${formattedNum}` : formattedNum
}

/**
 * Returns reduced array based on the max number provided
 * @param {array} array
 * @param {number} max
 * @param {number} startIndex
 * @returns {array}
 */
export const downsampleToMax = (array, max, startIndex = 0) => {
  const overage = array.length / max
  const k = overage >= 2 ? 2 ** Math.round(Math.log2(overage)) : Math.round(overage)

  if (k < 2) return array
  return array.filter((_, index) => (overage > 2
    ? (startIndex + index) % k === 0
    : (startIndex + index) % k !== 0))
}

/**
 * Rounds to given number of decimal places
 * @param {number} value The number to round
 * @param {number} precision The number of decimal places to allow (if falsy, rounds to no decimal places)
 * @returns number
 */

export const roundToPrecision = (value, precision) => {
  if (!value) return 0
  if (!precision || typeof precision !== 'number') return Math.round(value)
  const factor = 10 ** precision

  return Math.round(value * factor) / factor
}
const ORDINALIZERS = {}
const SUFFIXES = {
  en: new Map([
    ['one', 'st'],
    ['two', 'nd'],
    ['few', 'rd'],
    ['other', 'th'],
  ]),
  get 'en-US'() { return this.en },
  // es: { get() { return '.\u1d52' } },
  // get 'es-MX'() { return this.es },
}

export const formatOrdinal = (n, initialLocale = 'en') => {
  const locale = initialLocale in SUFFIXES ? initialLocale : 'en'
  ORDINALIZERS[locale] ??= new Intl.PluralRules(locale, { type: 'ordinal' })
  const rule = ORDINALIZERS[locale].select(n)

  const suffix = SUFFIXES[locale].get(rule)
  return `${n}${suffix}`
}
