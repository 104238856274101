import { TARGET_RANGES } from '~/src/DataType/TargetRange/constants'
import {
  DRYING_FLOW,
  DRYING_FLOW_ROUTE,
  HARVEST_BASED_DRYING_FLOW_ROUTE,
  HARVEST_BASED_HARVESTING_FLOW_ROUTE,
  HARVEST_BASED_LOT_TO_PLANT_FLOW_ROUTE,
  HARVEST_BASED_PROCESSING_FLOW_ROUTE,
  HARVEST_BASED_VEG_TO_FLOWER_FLOW_ROUTE,
  HARVESTING_FLOW,
  LOT_TO_PLANT_FLOW,
  PROCESSING_FLOW,
  PROCESSING_FLOW_ROUTE,
  VEG_TO_FLOWER_FLOW,
} from '~/src/Flow/constants'
import { REPLACE_PLANT } from '~/src/Inventory/constants'
import createLogger from '~/src/Lib/Logging'
import { lazyWithRetries } from '~/src/Lib/Utils'
import { SUBSTRATE_SETTINGS } from '~/src/Room/SubstrateSettings/constants'
import { TASK_EDITOR, TASK_EDITOR_ROUTE } from '~/src/Task/TaskManager/constants'

import Forbidden from '../Routes/Forbidden'
import NotFound from '../Routes/NotFound'

const HarvestView = lazyWithRetries(() => import('~/src/Annotations/HarvestView'))
const ManualReadingForm = lazyWithRetries(() => import('~/src/Annotations/ReadingForm'))
const ConfirmOptionDialog = lazyWithRetries(() => import('~/src/Compliance/Inbox/ConfirmOptionDialog'))
const CultivarsForm = lazyWithRetries(() => import('~/src/Cultivars/Form'))
const TargetRangeDelete = lazyWithRetries(() => import('~/src/DataType/TargetRange/Delete'))
const DayNightTargetRangeView = lazyWithRetries(() => import('~/src/DataType/TargetRange/View/DayNightView'))
const DayNightTargetRangesDialog = lazyWithRetries(() => import('~/src/DataType/TargetRange/DayNightDialog'))
const DeviceActivation = lazyWithRetries(() => import('~/src/Device/ActivationDialog'))
const DeviceSearch = lazyWithRetries(() => import('~/src/Device/ActivationDialog/Search'))
const FacilityForm = lazyWithRetries(() => import('~/src/Facility/Form'))
const FlowDiscardRoute = lazyWithRetries(() => import('~/src/Flow/Discard/Route'))
const HarvestingFlow = lazyWithRetries(() => import('~/src/Flow/Harvesting'))
const ProcessingFlow = lazyWithRetries(() => import('~/src/Flow/Processing'))
const LotToPlant = lazyWithRetries(() => import('~/src/Harvest/Transition/LotToPlant'))
const PhaseAdvance = lazyWithRetries(() => import('~/src/Harvest/Transition/PhaseAdvance'))
const PhaseDelay = lazyWithRetries(() => import('~/src/Harvest/Transition/PhaseDelay'))
const ReplacePlant = lazyWithRetries(() => import('~/src/Inventory/ReplacePlant'))
const InventoryModal = lazyWithRetries(() => import('~/src/Inventory/Modal'))
const ReplaceInvalidTag = lazyWithRetries(() => import('~/src/Inventory/ReplaceInvalidTag'))
const PesticideApplicationDelete = lazyWithRetries(() => import('~/src/IPM/Application/Delete'))
const PesticideApplicationForm = lazyWithRetries(() => import('~/src/IPM/Application/Form'))
const PesticideApplicationView = lazyWithRetries(() => import('~/src/IPM/Application/View'))
const PesticideDelete = lazyWithRetries(() => import('~/src/IPM/Pesticide/Delete'))
const PesticideForm = lazyWithRetries(() => import('~/src/IPM/Pesticide/FormDialog'))
const IrrigationControllerDialog = lazyWithRetries(() => import('~/src/Irrigation/ControllerDialog'))
const IrrigationControllerDelete = lazyWithRetries(() => import('~/src/Irrigation/ControllerDialog/Delete'))
const ModbusRegistersDialog = lazyWithRetries(() => import('~/src/Irrigation/ModbusRegistersDialog'))
const IrrigationScheduleDelete = lazyWithRetries(() => import('~/src/Irrigation/Delete'))
const IrrigationScheduleDialogRoute = lazyWithRetries(() => import('~/src/Irrigation/Dialog').then(({
  IrrigationScheduleDialogRoute: isdRoute
}) => ({ default: isdRoute })))
const IrrigationJournalLogDialogsWrapper = lazyWithRetries(() => import('~/src/Irrigation/Journal/Wrapper'))
const ImportTags = lazyWithRetries(() => import('~/src/Metrc/Tags/ImportTags'))
const NoteDelete = lazyWithRetries(() => import('~/src/Note/Delete'))
const NoteForm = lazyWithRetries(() => import('~/src/Note/Form'))
const NoteView = lazyWithRetries(() => import('~/src/Note/View'))
const BulkManualReadingForm = lazyWithRetries(() => import('~/src/Readings/ManualReading/BulkForm'))
const ManualReadingDelete = lazyWithRetries(() => import('~/src/Readings/ManualReading/Delete'))
const ManualReadingView = lazyWithRetries(() => import('~/src/Readings/ManualReading/View'))
const SubstrateSettingsDialog = lazyWithRetries(() => import('~/src/Room/SubstrateSettings'))
const RoomForm = lazyWithRetries(() => import('~/src/Room/Form'))
const LightScheduleDialogRoute = lazyWithRetries(() => import('~/src/Room/LightSchedule/Dialog'))
const LightScheduleDelete = lazyWithRetries(() => import('~/src/Room/LightSchedule/Delete'))
const LightScheduleView = lazyWithRetries(() => import('~/src/Room/LightSchedule/View'))
const ConfigureDevice = lazyWithRetries(() => import('~/src/Setup/Devices/ConfigureDevice'))
const GradeDelete = lazyWithRetries(() => import('~/src/Setup/Grades/Delete'))
const GradeForm = lazyWithRetries(() => import('~/src/Setup/Grades/GradeForm'))
const ItemDelete = lazyWithRetries(() => import('~/src/Setup/Items/Delete'))
const ItemForm = lazyWithRetries(() => import('~/src/Setup/Items/ItemForm'))
const SupportForm = lazyWithRetries(() => import('~/src/Support/SupportForm'))
const TaskManager = lazyWithRetries(() => import('~/src/Task/TaskManager/Dialog/'))
const TaskDelete = lazyWithRetries(() => import('~/src/Task/Delete'))
const TaskReviewHours = lazyWithRetries(() => import('~/src/Task/ReviewHours'))
const TaskForm = lazyWithRetries(() => import('~/src/Task/TaskFormRoute'))
const TaskView = lazyWithRetries(() => import('~/src/Task/TaskViewRoute'))

const logger = createLogger('Dialog/routes')

/**
 * Defines dialog routes
 * Default route pattern is /<1stLevelKey>/:id/<2ndLevelKey>
 * When second level key is "new" or "default", the pattern is /<1stLevelKey>/<2ndLevelKey>
 * Individual second level properties can override the pattern and define alias routes via
 * the "pattern" and "aliases" properties
 */
export const SCHEMA_ACTION_TO_ROUTE = {
  devices: {
    default: {
      Component: DeviceActivation,
      actions: ['change_device'],
    },
    search: {
      Component: DeviceSearch,
      actions: ['change_device'],
      pattern: '/devices/search',
    },
  },
  events: {
    resolve: {
      Component: ConfirmOptionDialog,
      autoFetch: false,
      actions: ['sync_processing', 'sync_cultivation', 'destroy_plant'],
      anyAction: true,
      features: 'METRC_COMMON_FIXES',
    }
  },
  facility: {
    edit: {
      Component: FacilityForm,
      actions: ['change_facility'],
      pattern: '/facilityEdit',
    },
  },
  facilityCultivars: {
    new: {
      Component: CultivarsForm
    },
    edit: {
      Component: CultivarsForm
    },
  },
  flows: {
    discard: {
      Component: FlowDiscardRoute
    },
  },
  grades: {
    new: {
      Component: GradeForm
    },
    edit: {
      Component: GradeForm
    },
    deleting: {
      Component: GradeDelete
    },
  },
  harvests: {
    phaseAdvance: {
      Component: PhaseAdvance,
      pattern: '/phaseAdvance/:id',
      aliases: ['/phaseAdvance'],
      actions: ['change_harvest'],
      features: ['METRC'],
      apps: 'AROYA',
    },
    phaseDelay: {
      Component: PhaseDelay,
      pattern: '/phaseDelay/:id/:previousSubId',
      aliases: ['/phaseDelay'],
    },
    view: {
      Component: HarvestView,
      actions: ['view_harvest'],
    },
    [HARVESTING_FLOW]: {
      Component: HarvestingFlow,
      actions: ['harvest_flow'],
      pattern: HARVEST_BASED_HARVESTING_FLOW_ROUTE,
    },
    [DRYING_FLOW]: {
      Component: ProcessingFlow,
      features: ['METRC'],
      actions: ['end_drying_flow'],
      aliases: [DRYING_FLOW_ROUTE, HARVEST_BASED_DRYING_FLOW_ROUTE],
    },
    [PROCESSING_FLOW]: {
      Component: ProcessingFlow,
      actions: ['processing_flow'],
      features: ['METRC'],
      apps: 'AROYA',
      aliases: [PROCESSING_FLOW_ROUTE, HARVEST_BASED_PROCESSING_FLOW_ROUTE],
    },
    [LOT_TO_PLANT_FLOW]: {
      Component: LotToPlant,
      additionalProps: { flow: LOT_TO_PLANT_FLOW },
      features: ['METRC'],
      actions: ['lot2plant_flow'],
      aliases: [HARVEST_BASED_LOT_TO_PLANT_FLOW_ROUTE],
    },
    [VEG_TO_FLOWER_FLOW]: {
      Component: LotToPlant,
      additionalProps: { flow: VEG_TO_FLOWER_FLOW },
      features: ['METRC'],
      actions: ['lot2plant_flow'],
      aliases: [HARVEST_BASED_VEG_TO_FLOWER_FLOW_ROUTE],
    },
  },
  harvestBatches: {
    view: {
      Component: InventoryModal,
    },
  },
  harvestPhases: {
    view: {
      Component: HarvestView,
    },
  },
  irrigationControllers: {
    new: {
      Component: IrrigationControllerDialog,
    },
    edit: {
      Component: IrrigationControllerDialog,
    },
    configure: {
      Component: IrrigationControllerDialog,
    },
    deleting: {
      Component: IrrigationControllerDelete,
    },
    view: {
      Component: IrrigationControllerDialog,
    },
    registers: {
      Component: ModbusRegistersDialog,
    },
  },
  irrigationSchedules: {
    new: {
      Component: IrrigationScheduleDialogRoute,
      aliases: ['/irrigationTemplates/new']
    },
    edit: {
      autoFetch: false,
      Component: IrrigationScheduleDialogRoute,
      aliases: ['/irrigationTemplates/:id/edit']
    },
    deleting: {
      autoFetch: false,
      Component: IrrigationScheduleDelete,
      aliases: ['/irrigationTemplates/:id/deleting']
    },
  },
  irrigation: {
    default: {
      Component: IrrigationJournalLogDialogsWrapper,
      pattern: '/irrigation/:type/:id',
    }
  },
  items: {
    new: {
      Component: ItemForm,
    },
    edit: {
      Component: ItemForm,
    },
    deleting: {
      Component: ItemDelete,
    },
  },
  lightSchedules: {
    new: {
      Component: LightScheduleDialogRoute,
    },
    view: {
      Component: LightScheduleView,
    },
    edit: {
      Component: LightScheduleDialogRoute,
    },
    deleting: {
      Component: LightScheduleDelete
    },
  },
  loggers: {
    edit: {
      Component: ConfigureDevice,
    },
  },
  notes: {
    new: {
      Component: NoteForm,
      aliases: ['/notes/new/:date'],
    },
    view: {
      Component: NoteView,
    },
    edit: {
      Component: NoteForm,
    },
    deleting: {
      Component: NoteDelete
    },
  },
  packages: {
    view: {
      Component: InventoryModal,
    },
    replaceInvalidTag: {
      Component: ReplaceInvalidTag,
      features: ['METRC'],
    }
  },
  pesticideApplications: {
    new: {
      Component: PesticideApplicationForm,
      actions: ['change_pesticide_application'],
      aliases: ['/pesticideApplications/new/:date']
    },
    view: {
      Component: PesticideApplicationView
    },
    edit: {
      Component: PesticideApplicationForm,
      actions: ['change_pesticide_application'],
    },
    deleting: {
      Component: PesticideApplicationDelete,
      actions: ['change_pesticide_application'],
    },
  },
  pesticides: {
    new: {
      Component: PesticideForm,
      actions: ['change_pesticide_application'],
    },
    edit: {
      Component: PesticideForm,
      actions: ['change_pesticide_application'],
    },
    deleting: {
      Component: PesticideDelete,
      actions: ['change_pesticide_application'],
    },
  },
  plants: {
    view: {
      Component: InventoryModal,
    },
    replaceInvalidTag: {
      Component: ReplaceInvalidTag,
      features: ['METRC'],
    }
  },
  plantBatches: {
    view: {
      Component: InventoryModal,
    },
    replaceInvalidTag: {
      Component: ReplaceInvalidTag,
      features: ['METRC'],
    }
  },
  readings: {
    new: {
      Component: BulkManualReadingForm,
      aliases: ['/readings/new/:date'],
    },
    view: {
      Component: ManualReadingView,
    },
    edit: {
      Component: ManualReadingForm,
    },
    deleting: {
      Component: ManualReadingDelete
    },
  },
  rooms: {
    new: {
      Component: RoomForm,
      actions: ['change_facility'],
    },
    get edit() { return this.new },
  },
  [SUBSTRATE_SETTINGS]: {
    default: {
      Component: SubstrateSettingsDialog
    }
  },
  support: {
    default: {
      Component: SupportForm
    }
  },
  [TARGET_RANGES]: {
    view: {
      Component: DayNightTargetRangeView,
      autoFetch: false,
      noEntity: true,
      actions: ['view_room'],
    },
    default: {
      Component: DayNightTargetRangesDialog,
      autoFetch: false,
      actions: ['change_targetrange'],
      noEntity: true,
    },
    deleting: {
      Component: TargetRangeDelete,
    },
  },
  tasks: {
    new: {
      Component: TaskForm,
      aliases: ['/tasks/new/:date'],
      actions: ['view_task'],
    },
    newIPM: {
      hasId: false,
      Component: TaskForm,
      additionalProps: {
        category: 'PEST',
      },
      actions: ['view_task'],
    },
    view: {
      Component: TaskView,
      actions: ['view_task'],
    },
    completing: {
      Component: TaskView,
      additionalProps: {
        completing: true
      },
      actions: ['view_task'],
    },
    edit: {
      Component: TaskForm,
      actions: ['view_task'],
    },
    deleting: {
      Component: TaskDelete,
      actions: ['view_task'],
    },
    duplicate: {
      Component: TaskForm,
      actions: ['view_task'],
    },
    review: {
      Component: TaskReviewHours,
      actions: ['view_task'],
    },
    taskEditor: {
      Component: TaskManager,
      tiers: ['GO'],
      negate: ['tiers'],
      actions: ['change_task'],
      pattern: `/${TASK_EDITOR}`,
      aliases: [TASK_EDITOR_ROUTE],
      params: {},
    }
  },
  tags: {
    default: {
      Component: ImportTags
    }
  },
  [REPLACE_PLANT]: {
    default: {
      Component: ReplacePlant,
      features: ['METRC'],
    }
  },
}

export default Object.entries(SCHEMA_ACTION_TO_ROUTE).reduce((acc, [schema, actions]) => {
  Object.entries(actions).forEach(([action, opts]) => {
    const isDefault = action === 'default'
    const isNew = action === 'new'
    const isView = action === 'view'
    const {
      aliases,
      hasId = !isDefault && !isNew,
      hasAction = !isDefault && !isView,
      pattern: optsPattern,
      ...restOpts
    } = opts
    const params = { schema, action }
    let pattern = optsPattern
    if (!pattern) {
      if (hasId) {
        pattern = hasAction ? `/${schema}/:id/${action}` : `/${schema}/:id`
      } else {
        pattern = hasAction ? `/${schema}/${action}` : `/${schema}`
      }
    }

    const config = {
      params,
      ...restOpts,
    }

    if (config.actions && !config.PermissionFallback) {
      config.PermissionFallback = Forbidden
    }
    if (config.features && !config.Fallback) {
      config.Fallback = NotFound
    }

    acc[pattern] = config

    if (Array.isArray(aliases)) {
      aliases.forEach(alias => {
        acc[alias] = config
      })
    }
  })
  return acc
}, {})
