import { useMemo } from 'react'

import i18n from 'i18n-literally'
import memoizeOne from 'memoize-one'
import { pick } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { Typography } from '@mui/material'

import {
  getBattery,
  getSignalIcon,
  getStatus,
  rssiToSignal,
} from '~/src/Device/utils'
import {
  EMPTY_OBJECT,
  formattedDate,
  getDateTime,
  getShortDateDiff,
  getTimeDiffHours,
} from '~/src/Lib/Utils'

export const getBatteryPercentageWithColor = (value, classes) => {
  if (value >= 30) {
    return <Typography color="primary">{value}%</Typography>
  }
  if (value >= 20) {
    return <Typography className={classes.textWarning}>{value}%</Typography>
  }
  return <Typography color="error">{value}%</Typography>
}

export const getSignalPercentageWithColor = (value, classes) => {
  if (value >= 50) {
    return <Typography color="primary">{value}%</Typography>
  }
  if (value >= 25) {
    return <Typography className={classes.textWarning}>{value}%</Typography>
  }
  return <Typography color="error">{value}%</Typography>
}

const formatDateTime = ts => {
  if (!ts) return 'Never'
  const tsDT = getDateTime(ts)
  const sameDay = getDateTime('now').hasSame(tsDT, 'day')

  return formattedDate(ts, sameDay ? 'SHORT_TIME' : 'SHORT_DATETIME')
}
const getStatusTooltipText = (online, lastCommunication, deepSleep) => {
  if (deepSleep) return `Deep sleep since: ${formatDateTime(deepSleep)}`
  if (lastCommunication && !online) return `Offline since: ${formatDateTime(lastCommunication)}`
  return `Last communicated: ${formatDateTime(lastCommunication)}`
}

const devicePicker = pick([
  'id',
  'aqualab',
  'battery',
  'assigned',
  'calibrationType',
  'createdOn',
  'deviceName',
  'provisionedOn',
  'serialNumber',
])
// Convert device information into useful data fields for the table
export const getDeviceDataFactory = memoizeOne(({
  models,
  rooms = EMPTY_OBJECT,
  zones = EMPTY_OBJECT
}, modelVariants = EMPTY_OBJECT) => device => {
  if (!device || !models) return null
  const {
    battery,
    deepSleep,
    lastCommunication,
    online,
    room: roomId,
    rssi,
    linkQuality,
    sensor,
    zone: zoneId,
  } = device

  const modelKey = device.sensor?.modelKey ?? device.modelKey
  let model = modelKey in models ? models[modelKey] : null
  if (device.sensor?.modelVariant ?? device.modelVariant) {
    const { [device.sensor.modelVariant ?? device.modelVariant]: variant } = modelVariants
    model = variant ?? model
  }
  const signal = linkQuality ?? rssiToSignal(rssi)

  const data = {
    ...devicePicker(device),
    // derived data
    icons: {
      BatteryIcon: getBattery(battery).Icon,
      SignalIcon: getSignalIcon(signal).Icon,
      StatusIcon: getStatus(online, deepSleep),
    },
    isGateway: model?.gateway ?? false,
    lastComm: lastCommunication ? getShortDateDiff(lastCommunication) : 'Never',
    model: model?.name ?? '',
    room: roomId in rooms ? rooms[roomId] : null,
    signal,
    statusTooltip: getStatusTooltipText(online, lastCommunication, deepSleep),
    zone: zoneId in zones ? zones[zoneId] : null,
  }

  if (sensor) {
    data.sensorLastComm = getShortDateDiff(sensor.lastCommunication)
    data.sensorStatusTooltip = getStatusTooltipText(sensor.online, sensor.lastCommunication)
    data.icons.SensorStatusIcon = getStatus(sensor.online)
    if (sensor.calibrationType !== data.calibrationType) {
      data.calibrationType = sensor.calibrationType
    }
  }

  return data
})

export const getDevicePendingStatus = (devicesProvisioningStatus, device = false) => {
  if (device?.serialNumber && devicesProvisioningStatus) {
    return devicesProvisioningStatus.find(d => d?.serialNumber === device?.serialNumber)?.status === 'PENDING'
  }
  return false
}

export const usePendingDevices = () => {
  const { devicesProvisioningStatus } = useConnect('selectDevicesProvisioningStatus')
  return useMemo(() => devicesProvisioningStatus.some(({ status }) => status === 'PENDING'), [devicesProvisioningStatus])
}

export const DEVICE_STATES = {
  ALL: '',
  INSTALLED: 'installed',
  NOT_INSTALLED: 'notInstalled',
  LOW_BATTERY: 'lowBattery',
  LOW_SIGNAL: 'lowSignal',
  ASLEEP: 'asleep',
  OFFLINE: 'offline',
  RECENTLY_PROVISIONED: 'recentlyProvisioned',
}
export const stateFilters = [
  { key: DEVICE_STATES.ALL, label: i18n`All Devices` },
  { key: DEVICE_STATES.INSTALLED, label: i18n`Assigned` },
  { key: DEVICE_STATES.NOT_INSTALLED, label: i18n`Unassigned` },
  { key: DEVICE_STATES.LOW_BATTERY, label: i18n`Low Battery` },
  { key: DEVICE_STATES.LOW_SIGNAL, label: i18n`Low Signal` },
  { key: DEVICE_STATES.ASLEEP, label: i18n`Asleep` },
  { key: DEVICE_STATES.OFFLINE, label: i18n`Offline` },
  { key: DEVICE_STATES.RECENTLY_PROVISIONED, label: i18n`Recently Activated` },
]

export const useNewDevices = devices => {
  const {
    devicesProvisioningStatus,
    provisioningStatusRaw
  } = useConnect(
    'selectProvisioningStatusRaw',
    'selectDevicesProvisioningStatus'
  )
  const { pollingStart } = provisioningStatusRaw
  const pendingDevices = useMemo(() => devicesProvisioningStatus.filter(d => d.status === 'PENDING'), [devicesProvisioningStatus])
  const newDevices = useMemo(() => devices.reduce((acc, d) => {
    const justProvisioned = getTimeDiffHours(getDateTime(pollingStart), getDateTime(d.provisionedOn)) > 0
    const wasProvisioned = getTimeDiffHours(getDateTime(pollingStart), getDateTime(d.provisionedOn)) < 24
    const gatewayIsPending = pendingDevices.find(pendingDevice => d.serialNumber === pendingDevice.serialNumber)
    if (pollingStart && justProvisioned && wasProvisioned && !d?.assigned && !gatewayIsPending) {
      return acc + 1
    }
    return acc
  }, 0), [devices, pollingStart, pendingDevices])
  return [pendingDevices, newDevices]
}
